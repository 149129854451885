/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_self
 *= require actiontext
 */

$blue:    #15345E !default;
$indigo:  #1F9FA6 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #AC465E !default;
$orange:  #F65627 !default;
$yellow:  #FDF528 !default;
$green:   #A0D83A !default;
$teal:    #20c997 !default;
$cyan:    #768AAF !default;
$dark:    #343a40 !default;
// $primary: $red;

@import "../../../node_modules/bootstrap/scss//bootstrap.scss";
// @import url('https://fonts.googleapis.com/css?family=M+PLUS+1p:400,700&display=swap&subset=japanese');

// body {
//   font-family: 'M PLUS 1p', sans-serif;
// }
 
a {
  text-decoration: underline;
}

a.navbar-brand, a.btn {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}
 